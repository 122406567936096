import Icon from "@/components/icons/icons";
import Link from "@/components/utility/link";
import Paragraph from "@/components/utility/paragraph";
import { ChipT, DesignSystemComponent } from "@/types/design-system-types";
import { addHttps, cn } from "@/utils/helpers";

import { ComponentError } from "@/utils/system/component-error";
import Page from "@/utils/system/page";
import Image from "next/image";
import { ElementType } from "react";

const Chip = ({
  data, // All incoming Contentful data
  id, // For scroll spy anchors
  debug, // Debugging info from Contentful

  // Contentful controlled Props
  title = data?.fields.title || "",
  icon = data?.fields.icon,
  // icon_v2 = data?.fields.icon_v2,
  image = data?.fields.image,
  iconPosition = data?.fields.iconPosition,
  theme = data?.fields.theme,
  size = data?.fields.size ?? "md",
  enableBoldText = data?.fields.enableBoldText ?? false,
  url = data?.fields.url,

  // Internal props
  className,
  ...props
}: DesignSystemComponent<ChipT>) => {
  try {
    // TODO Kiril: fix this
    // if (data?.fields?.internalName === "SHARE PAGE VIA FACEBOOK") {
    //   url = "https://www.facebook.com/sharer/sharer.php?u=" + window?.location?.href;
    // }

    // if (data?.fields?.internalName === "SHARE PAGE VIA TWITTER/X") {
    //   url = "https://twitter.com/intent/tweet?url=" + window?.location?.href;
    // }

    // if (data?.fields?.internalName === "SHARE PAGE VIA LINKEDIN") {
    //   url =
    //     "https://www.linkedin.com/shareArticle?mini=true&url=" + window?.location?.href;
    // }

    // if (data?.fields?.internalName === "SHARE PAGE VIA EMAIL") {
    //   url = "mailto:?subject=Check out this page&body=" + window?.location?.href;
    // }

    const themeClass = Page.getThemeClass(theme);
    const sizeClass = `n-chip-${size}`;
    const iconPositionClass = iconPosition === "right" ? "n-chip-flip" : "";

    const ParentComponent = url ? Link : ("div" as ElementType);

    return (
      <ParentComponent
        {...props}
        className={cn(
          "n-chip",
          themeClass,
          iconPositionClass,
          sizeClass,
          className,
          url && "underline hover:text-azure-100"
        )}
        data-anchor-type="chip"
        ref={id}
        {...(url && { href: url })}
      >
        {/* DEBUGGING INFO FOR LOCAL */}
        {debug}

        {/* Icon takes precedence over image if both fields are present */}
        {icon && (
          <div className="accent-stroke">
            <Icon
              name={icon}
              className="n-chip-icon"
              onClick={() => Page.editField(data, "icon")}
            />
          </div>
        )}

        {/* Use image if present & icon is not present. Chip images should be small & icon-like */}
        {image && !icon && image.fields.file.details.image && (
          <Image
            src={addHttps(image.fields?.file?.url)}
            alt=""
            width={image.fields?.file?.details?.image.width}
            height={image.fields?.file?.details?.image.height}
            className="n-chip-icon"
            onClick={() => Page.editField(data)}
          />
        )}

        {/* Title */}
        {title && (
          <span className="n-chip-titleslot">
            <Paragraph
              onClick={() => Page.editField(data, "title")}
              fontSize={size}
              fontWeight={enableBoldText ? "semibold" : "normal"}
              className="n-chip-title"
            >
              {/* {url ? <Link href={url}>{title}</Link> : } */}
              {title}
            </Paragraph>
          </span>
        )}
      </ParentComponent>
    );
  } catch (error) {
    return <ComponentError error={error} data={data} />;
  }
};

export default Chip;
